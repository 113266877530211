<template>
  <v-container fill-height fluid class="background">
    <v-row align="center" justify="center" class="d-flex flex-column">
      <v-img :src="light_mode_logo" height="50" max-width="150" contain></v-img>
      <p class="text-h3 text-sm-h2 text-center ma-2 pa-4 secondary--text font-weight-black">
        Quiz Ended.<br /><span class="text-h4 text-sm-h3 font-weight-medium">Until Next Time. Ciao!</span>
      </p>
      <router-link
        v-for="link in homelinks"
        :key="link.title"
        class="mx-4 text-h6 underline font-weight-bold secondary--text"
        text
        :to="link.route"
      >
        {{ link.title }}
      </router-link>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ParticipantEndQuiz",
  data() {
    return {
      light_mode_logo: require("@/assets/common/logo_lightmode.svg"),
      homelinks: [
        { icon: "home", title: "Qizto Home", route: { name: "Landing" } },
        { icon: "play", title: "Play Again", route: { name: "Join" } },
      ],
    }
  },
}
</script>

<style lang="scss" scoped></style>
