var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "background", attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        {
          staticClass: "d-flex flex-column",
          attrs: { align: "center", justify: "center" }
        },
        [
          _c("v-img", {
            attrs: {
              src: _vm.light_mode_logo,
              height: "50",
              "max-width": "150",
              contain: ""
            }
          }),
          _c(
            "p",
            {
              staticClass:
                "text-h3 text-sm-h2 text-center ma-2 pa-4 secondary--text font-weight-black"
            },
            [
              _vm._v(" Quiz Ended."),
              _c("br"),
              _c(
                "span",
                { staticClass: "text-h4 text-sm-h3 font-weight-medium" },
                [_vm._v("Until Next Time. Ciao!")]
              )
            ]
          ),
          _vm._l(_vm.homelinks, function(link) {
            return _c(
              "router-link",
              {
                key: link.title,
                staticClass:
                  "mx-4 text-h6 underline font-weight-bold secondary--text",
                attrs: { text: "", to: link.route }
              },
              [_vm._v(" " + _vm._s(link.title) + " ")]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }